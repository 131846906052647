import React from 'react';
import { Grid2 as Grid, Divider } from '@mui/material';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { AnnouncementsState } from '/@/store/app/announcementsConnection';
import { AnnouncementItem } from '/@/components/shared/announcementItem/Index';

type OwnProps = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  announcements: AnnouncementsState;
  announcementsConnectionStatus: Status;
};

const Presenter: React.VFC<OwnProps> = ({
  infiniteScrollTarget,
  announcements,
  announcementsConnectionStatus,
}) => (
  <Grid data-cy="announcementList">
    {announcementsConnectionStatus !== STATUS.LOADING ? (
      <>
        {announcements?.map(
          (announcement, index) =>
            announcement && (
              <Grid key={index}>
                <Divider />
                <AnnouncementItem announcement={announcement} />
              </Grid>
            ),
        )}
      </>
    ) : (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
