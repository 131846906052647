import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';

const Container: React.VFC<Record<string, never>> = () => {
  const { pushClickEvent } = useDataLayer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleNavigatePointLogs = () => {
    pushClickEvent({
      clickItem: 'handleNavigatePointLogsButton',
      customData: {
        clickItem: 'handleNavigatePointLogsButton',
      },
    });
    navigate(PATHS.POINT_POINT_LOGS);
  };
  const handleNavigatePointBrands = () => {
    pushClickEvent({
      clickItem: 'handleNavigatePointBrandsButton',
      customData: {
        clickItem: 'handleNavigatePointBrandsButton',
      },
    });
    navigate(PATHS.POINT_BRANDS);
  };
  const pointWallet = useAppSelector((state) => state.app.pointWallet);
  const getPointWalletStatus = useAppSelector(
    (state) => state.api.getPointWallet.status,
  );

  const { pushCustomEvent } = useDataLayer();

  useEffect(() => {
    // ウィジェット表示計測用
    pushCustomEvent({
      eventName: 'displayHomeWidget',
      customData: {
        eventName: 'displayHomeWidget',
      },
    });
  }, [pushCustomEvent]);

  useEffect(() => {
    if (pointWallet === null) {
      dispatch(getPointWalletThunk()).catch(() => {});
    }
  }, [dispatch, pointWallet]);

  return (
    <Presenter
      pointWallet={pointWallet}
      getPointWalletStatus={getPointWalletStatus}
      handleNavigatePointBrands={handleNavigatePointBrands}
      handleNavigatePointLogs={handleNavigatePointLogs}
    />
  );
};

export { Container as PointCard };
