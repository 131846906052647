import React, { useEffect, useMemo } from 'react';
import { Presenter } from './Presenter';
import { readAnnouncementsThunk } from '/@/store/api/mutations/readAnnouncements';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getAnnouncementsConnectionThunk } from '/@/store/api/queries/getAnnouncementsConnection';
import { initialiseAnnouncementsConnection } from '/@/store/app/announcementsConnection/actions';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = 'あなた宛';
  const dispatch = useAppDispatch();
  const readAnnouncementsStatus = useAppSelector(
    (state) => state.api.readAnnouncements.status,
  );
  const unreadAnnouncementsCountForYou = useAppSelector(
    (state) => state.app.currentUser?.unreadAnnouncementsCountByCategoryUid.forYou,
  );
  const announcementsConnection = useAppSelector(
    (state) => state.app.announcementsConnection,
  );
  const isDisabledReadButton = useMemo(() => {
    return (
      unreadAnnouncementsCountForYou === undefined || unreadAnnouncementsCountForYou === 0
    );
  }, [unreadAnnouncementsCountForYou]);

  const handleReadAllAnnouncements = () => {
    dispatch(readAnnouncementsThunk({
      categoryUid: UserAnnouncementTypeCategoryUidEnumType.ForYou,
    })).then(() => {
      dispatch(initialiseAnnouncementsConnection());
    });
  };

  useEffect(() => {
    if (!announcementsConnection) {
      dispatch(getAnnouncementsConnectionThunk({
        after: null,
        categoryUidIn: [UserAnnouncementTypeCategoryUidEnumType.ForYou],
      }));
    }
  }, [dispatch, announcementsConnection]);

  return (
    <Presenter
      headerTitle={headerTitle}
      handleReadAllAnnouncements={handleReadAllAnnouncements}
      readAnnouncementsStatus={readAnnouncementsStatus}
      isDisabledReadButton={isDisabledReadButton}
      isNoAnnouncement={announcementsConnection?.announcements?.length === 0}
    />
  );
};

export { Container as AnnouncementsPage };
