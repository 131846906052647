import React from 'react';
import { Presenter } from './Presenter';
import { LpTypes } from '../types';

type OwnProps = {
  lpType: LpTypes;
};
const Container: React.FC<OwnProps> = ({ lpType }) => {
  return <Presenter lpType={lpType} />;
};
export { Container as BenefitsOfConvertSection };
