import {
  DestinationEnum,
  DestinationOption,
  GiftSavingOption,
  OriginEnum,
  PointSerialOption,
} from '../api/graphql/publicApi/types';
import { PATHS } from '../routes/paths';

export const createOauthSessionOption = (urlSearchParams: URLSearchParams) => {
  // queryStringを元にCreateOauthSession用のoptionを作成
  const url = urlSearchParams.get('gift_url');
  const origin = urlSearchParams.get('gift_origin') as OriginEnum | null;
  const referer = urlSearchParams.get('referer');
  // LP経由の場合はconvertToPoint=trueで固定
  const isConvertToPointLP =
    location.pathname === PATHS.LP_CONVERT_TO_POINT ||
    location.pathname === PATHS.LP_CAMPAIGN_TIKTOK;
  const convertToPoint =
    isConvertToPointLP || urlSearchParams.get('convert_to_point') === 'true';
  const destination = urlSearchParams.get(
    'destination',
  ) as DestinationEnum | null;
  const serialCode = urlSearchParams.get('serial_code');

  const giftSavingOption: GiftSavingOption | null =
    url && origin ? { url, origin, referer, convertToPoint } : null;
  const destinationOption: DestinationOption | null = destination
    ? { name: destination }
    : null;
  const pointSerialOption: PointSerialOption | null = serialCode
    ? { serialCode: serialCode }
    : null;

  return {
    giftSavingOption,
    destinationOption,
    pointSerialOption,
  };
};
