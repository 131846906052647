import React from 'react';
import { DetailSections } from '/@/components/shared/detailSections/Index';
import BenefitsOfConvert01 from '/@/assets/shared/lp/benefitsOfConvertSection/benefits-of-convert-01.png';
import BenefitsOfConvert03 from '/@/assets/shared/lp/benefitsOfConvertSection/benefits-of-convert-03.png';
import { LP_TYPES, LpTypes } from '../types';

type Props = {
  lpType: LpTypes;
};
const Presenter: React.VFC<Props> = ({ lpType }) => (
  <div data-cy="signInPageUsageSection">
    <DetailSections
      title="gift wallet の活用方法"
      subTitle="ポイントを有効活用"
      details={[
        {
          number: 1,
          title: '商品交換時にポイント還元',
          description:
            'gift wallet内の対象の商品を交換すると、自動でgift wallet pointへの還元がされます。還元されたポイントは次回以降の商品交換時に利用できます。',
          imgTitle: '商品例',
          img: BenefitsOfConvert01,
        },
        {
          number: 2,
          title: '有効期限の延長',
          description:
            lpType === LP_TYPES.LP_CHARGE_SERIAL_CODE
              ? 'えらべるPayをgift wallet にポイント移行することで有効期限が自動で延長されます。'
              : 'gift walletにポイント移行することで有効期限が自動で延長されます。',
        },
        {
          number: 3,
          title: '豊富な商品と交換',
          description:
            'gift walletでは多数の商品を取り扱っており、その中で自由に商品の交換ができます。',
          subDescription:
            '※ 交換対象の商品はご希望に沿えない場合がございます。ラインナップは順次拡大していく予定です。',
          img: BenefitsOfConvert03,
        },
        {
          number: 4,
          title: 'その他お得なキャンペーンに参加',
          description:
            'その他、gift walletではお得なキャンペーンを随時実施しています。沢山移行、交換してよりお得にギフトをゲットしましょう。',
        },
      ]}
      attention="※ 商品により各種変動がある場合がございます。"
    />
  </div>
);

export { Presenter };
