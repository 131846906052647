import React from 'react';
import { Grid2 as Grid, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { HeroSection } from './heroSection/Index';
import { AboutSection } from '/@/components/shared/lp/aboutSection/Index';
import { BenefitsOfConvertSection } from '/@/components/shared/lp/benefitsOfConvertSection/Index';
import { HowToUseSection } from '/@/components/shared/lp/howToUseSection/Index';
import { CampaignSection } from '/@/components/shared/lp/campaignSection/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { Footer } from '/@/components/templates/footer/Index';
import { PATHS } from '/@/routes/paths';
import campaingBackgroundImage from '/@/assets/page/lpPage/campaignLP/campaign-lp-hero-background.svg';
import { LP_TYPES } from '/@/components/shared/lp/types';

const StyledContainer = styled(Container)({
  padding: 0,
});
const StyledHeroSection = styled('section')({
  width: '100%',
  backgroundImage: `url(${campaingBackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});
const StyledCampaignSection = styled('section')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
}));
const StyledUsageSectionContainer = styled('section')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.hint,
}));
const StyledHowToUseSectionContainer = styled('section')({
  width: '100%',
});
const StyledLoginContainer = styled('section')(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
}));
const StyledLoginButton = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledConvertToPointNote = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  display: 'inline-block',
  width: '100%',
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontSize: '0.75rem',
}));
const StyledCautionTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = LoginOption;

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
}) => (
  <Grid container direction="column" alignContent="center">
    <StyledHeroSection>
      <StyledContainer maxWidth="sm">
        <HeroSection
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
        />
      </StyledContainer>
    </StyledHeroSection>
    <section>
      <StyledContainer maxWidth="sm">
        <AboutSection lpType={LP_TYPES.DEFALT} />
      </StyledContainer>
    </section>
    <StyledCampaignSection>
      <StyledContainer maxWidth="sm">
        <CampaignSection lpType={LP_TYPES.DEFALT}>
          <StyledLoginButton>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={null}
              flow={PATHS.LP_CAMPAIGN_TIKTOK}
            >
              ギフトをポイントに移行する
            </LoginButton>
            <StyledConvertToPointNote variant="caption">
              ※ポイントは残高全てが移行されます。ご注意ください。
            </StyledConvertToPointNote>
          </StyledLoginButton>
        </CampaignSection>
      </StyledContainer>
    </StyledCampaignSection>
    <StyledUsageSectionContainer>
      <StyledContainer maxWidth="sm">
        <BenefitsOfConvertSection lpType={LP_TYPES.DEFALT} />
      </StyledContainer>
    </StyledUsageSectionContainer>
    <StyledHowToUseSectionContainer>
      <StyledContainer maxWidth="sm">
        <HowToUseSection />
      </StyledContainer>
    </StyledHowToUseSectionContainer>
    <StyledLoginContainer>
      <StyledContainer maxWidth="sm">
        <LoginButton
          url={url}
          origin={origin}
          referer={referer}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={null}
          flow={PATHS.LP_CAMPAIGN_TIKTOK}
        >
          ギフトをポイントに移行する
        </LoginButton>
        <StyledConvertToPointNote variant="caption">
          ※ポイントは残高全てが移行されます。ご注意ください。
        </StyledConvertToPointNote>
        <StyledCautionTextContainer>
          <CautionText />
        </StyledCautionTextContainer>
      </StyledContainer>
    </StyledLoginContainer>
    <Footer />
  </Grid>
);

export { Presenter };
