import React from 'react';
import type { ReactElement } from 'react';
import { PATHS } from './paths';
import { LpConvertToPointPage } from '/@/components/page/lpPage/lpConvertToPointPage/Index';
import { LpChargeSerialCodePage } from '/@/components/page/lpPage/lpChargeSerialCodePage/Index';
import { LpCampaignTiktokPage } from '/@/components/page/lpPage/campaignLp/lpCampaignTiktok/Index';
import { SignInPage } from '/@/components/page/signInPage/Index';
import { OauthCallbackPage } from '../components/page/oauthPage/authorizationPage/Index';
import { AnnouncementsPage } from '/@/components/page/announcementsPage/Index';
import { PromotionsPage } from '/@/components/page/promotionsPage/Index';
import { HomePage } from '/@/components/page/homePage/Index';
import { GiftsPage } from '/@/components/page/giftsPage/Index';
import { GiftNewPage } from '/@/components/page/giftsPage/newPage/Index';
import { GiftShowPage } from '/@/components/page/giftsPage/showPage/Index';
import { GiftsEditPage } from '/@/components/page/giftsPage/editPage/Index';
import { InquiriesNewPage } from '/@/components/page/inquiriesPage/newPage/Index';
import { InquiriesCompletionPage } from '/@/components/page/inquiriesPage/completionPage/Index';
import { TermsOfServicePage } from '/@/components/page/termsOfServicePage/Index';
import { FrequentlyAskedQuestionsPage } from '/@/components/page/frequentlyAskedQuestions/Index';
import { UserProfileNewPage } from '/@/components/page/userPage/profilePage/newPage/Index';
import { NotFoundPage } from '/@/components/page/notFoundPage/Index';
import { PointBrandsPage } from '/@/components/page/pointPage/brandsPage/Index';
import { ItemsPage } from '/@/components/page/pointPage/brandsPage/itemsPage/Index';
import { PointPointLogsPage } from '/@/components/page/pointPage/pointLogsPage/Index';
import { CompletionPage as PointChargeCompletionPage } from '../components/page/pointPage/chargePage/completionPage/Index';
import { NewPage as PointChargeSerialCodeNewPage } from '../components/page/pointPage/chargePage/serialCodePage/newPage/Index';
import { CompletionPage as PointChargeSerialCodeCompletionPage } from '../components/page/pointPage/chargePage/serialCodePage/completionPage/Index';
import { ErrorPage as PointChargeSerialCodeErrorPage } from '../components/page/pointPage/chargePage/serialCodePage/errorPage/Index';
import { CompletionPage as PointExhangeCompletionPage } from '../components/page/pointExchange/completion/Index';
import { PointChargePage } from '../components/page/pointPage/chargePage/Index';
import type { PathValues } from './paths';
import { Navigate } from 'react-router-dom';
import { NonExsitentLpPage } from '../components/page/lpPage/nonExsitentLP/Index';
import { AnnouncementDetailPage } from '../components/page/announcementsPage/announcementDetailPage/Index';

type Route = {
  path: PathValues;
  element: ReactElement;
  private: boolean;
};
type Routes = Route[];

const routes: Routes = [
  {
    path: PATHS.ROOT,
    element: <Navigate to={PATHS.HOME} replace />,
    private: true,
  },
  {
    path: PATHS.LP_CONVERT_TO_POINT,
    element: <LpConvertToPointPage />,
    private: false,
  },
  {
    path: PATHS.LP_CHARGE_SERIAL_CODE,
    element: <LpChargeSerialCodePage />,
    private: false,
  },
  {
    path: PATHS.LP_CAMPAIGN_TIKTOK,
    element: <LpCampaignTiktokPage />,
    private: false,
  },
  // MEMO: 存在しないLPページはsignInPageへリダイレクト
  {
    path: PATHS.NON_EXISTENT_LP,
    element: <NonExsitentLpPage />,
    private: false,
  },
  {
    path: PATHS.SIGN_IN,
    element: <SignInPage />,
    private: false,
  },
  {
    path: PATHS.OAUTH_AUTHORIZATION,
    element: <OauthCallbackPage />,
    private: false,
  },
  {
    path: PATHS.ANNOUNCEMENTS,
    element: <AnnouncementsPage />,
    private: true,
  },
  {
    path: PATHS.ANNOUNCEMENT_DETAIL,
    element: <AnnouncementDetailPage />,
    private: true,
  },
  {
    path: PATHS.PROMOTIONS,
    element: <PromotionsPage />,
    private: true,
  },
  {
    path: PATHS.GIFTS,
    element: <GiftsPage />,
    private: true,
  },
  {
    path: PATHS.HOME,
    element: <HomePage />,
    private: true,
  },
  {
    path: PATHS.GIFTS_NEW,
    element: <GiftNewPage />,
    private: true,
  },
  {
    path: PATHS.GIFT,
    element: <GiftShowPage />,
    private: true,
  },
  {
    path: PATHS.GIFTS_EDIT,
    element: <GiftsEditPage />,
    private: true,
  },
  {
    path: PATHS.INQUIRIES_NEW,
    element: <InquiriesNewPage />,
    private: false,
  },
  {
    path: PATHS.INQUIRIES_COMPLETION,
    element: <InquiriesCompletionPage />,
    private: false,
  },
  {
    path: PATHS.TERMS_OF_SERVICE,
    element: <TermsOfServicePage />,
    private: false,
  },
  {
    path: PATHS.FREQUENTLY_ASKED_QUESTIONS,
    element: <FrequentlyAskedQuestionsPage />,
    private: false,
  },
  {
    path: PATHS.USER_PROFILE_NEW,
    element: <UserProfileNewPage />,
    private: true,
  },
  {
    path: PATHS.POINT_BRANDS,
    element: <PointBrandsPage />,
    private: true,
  },
  {
    path: PATHS.BRAND_ITEMS,
    element: <ItemsPage />,
    private: true,
  },
  {
    path: PATHS.ITEMS,
    element: <ItemsPage />,
    private: true,
  },
  {
    path: PATHS.POINT_POINT_LOGS,
    element: <PointPointLogsPage />,
    private: true,
  },
  {
    path: PATHS.POINT_EXCHANGE_COMPLETION,
    element: <PointExhangeCompletionPage />,
    private: true,
  },
  {
    path: PATHS.POINT_CHARGE,
    element: <PointChargePage />,
    private: true,
  },
  {
    path: PATHS.POINT_CHARGE_COMPLETION,
    element: <PointChargeCompletionPage />,
    private: true,
  },
  {
    path: PATHS.POINT_CHARGE_SERIAL_CODE_NEW,
    element: <PointChargeSerialCodeNewPage />,
    private: true,
  },
  {
    path: PATHS.POINT_CHARGE_SERIAL_CODE_COMPLETION,
    element: <PointChargeSerialCodeCompletionPage />,
    private: true,
  },
  {
    path: PATHS.POINT_CHARGE_SERIAL_CODE_ERROR,
    element: <PointChargeSerialCodeErrorPage />,
    private: true,
  },
  {
    path: PATHS.NOT_FOUND,
    element: <NotFoundPage />,
    private: false,
  },
];

export { routes };
