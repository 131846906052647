import React from 'react';
import {
  AppBar,
  Toolbar,
  SwipeableDrawer,
  List,
  ListItemText,
  ListSubheader,
  Divider,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { HeaderTitle } from '../headerComponents/headerTitle/Index';
import { HeaderLogo } from '../headerComponents/headerLogo/Index';
import { HeaderGrobalMenu } from '../headerComponents/headerGrobalMenu/Index';
import { CurrentUser } from '/@/store/app/currentUser/reducers';
// import { UnreadBadge } from '/@/components/shared/badge/unreadBadge/Index';

export const HEADER_HEIGHT = 48;
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: 'none',
  borderRight: 'none',
  borderLeft: 'none',
}));
const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  height: `${HEADER_HEIGHT}px`,
  minHeight: `${HEADER_HEIGHT}px`,
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
}));

const StyledMenuDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '30%',
    },
    [theme.breakpoints.up('md')]: {
      width: '10%',
    },
  },
}));
const StyledMenuListSubheader = styled(ListSubheader)({
  fontSize: '0.75rem',
});
const StyledMenuListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
  },
});

type Props = {
  headerTitle?: string;
  isLogoClickable: boolean;
  isMenuVisible: boolean;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
  handleNavigateBack: () => void;
  unreadAnnouncementsCount: CurrentUser['unreadAnnouncementsCountByCategoryUid'] | undefined;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  isLogoClickable,
  isMenuVisible,
  isMenuOpened,
  handleToggleMenu,
  handleClickGlobalMenu,
  handleNavigateBack,
  // unreadAnnouncementsCount,
}) => (
  <>
    <StyledAppBar
      position="fixed"
      variant="outlined"
      color="default"
      data-cy="header"
    >
      <StyledToolBar>
        {headerTitle ? (
          <HeaderTitle
            headerTitle={headerTitle}
            handleNavigateBack={handleNavigateBack}
          />
        ) : (
          <HeaderLogo isLogoClickable={isLogoClickable} />
        )}
        {isMenuVisible && (
          <HeaderGrobalMenu
            handleToggleMenu={handleToggleMenu}
            handleClickGlobalMenu={handleClickGlobalMenu}
          />
        )}
      </StyledToolBar>
    </StyledAppBar>

    <StyledMenuDrawer
      anchor="right"
      open={isMenuOpened}
      onOpen={() => {}} // Do nothing
      onClose={handleToggleMenu}
    >
      <List data-cy="menuList" component="nav" aria-label="menu-list">
        {/* <StyledMenuListSubheader>お知らせ</StyledMenuListSubheader>
        <ListItemButton
          data-cy="announcement"
          component={RouterLink}
          to={PATHS.ANNOUNCEMENTS}
        >
          <StyledMenuListItemText primary="あなた宛" />
          {unreadAnnouncementsCount?.forYou && <UnreadBadge />}
        </ListItemButton>

        <Divider component="li" /> */}
        <StyledMenuListSubheader>ギフト管理</StyledMenuListSubheader>
        <ListItemButton
          data-cy="listItemHome"
          component={RouterLink}
          to={PATHS.HOME}
        >
          <StyledMenuListItemText primary="マイギフト" />
        </ListItemButton>
        <ListItemButton
          data-cy="listItemGetGift"
          component={RouterLink}
          to={PATHS.PROMOTIONS}
        >
          <StyledMenuListItemText primary="もらえるギフト" />
        </ListItemButton>

        <Divider component="li" />

        <StyledMenuListSubheader>ヘルプ</StyledMenuListSubheader>
        <ListItemButton
          component={RouterLink}
          to={PATHS.FREQUENTLY_ASKED_QUESTIONS}
        >
          <StyledMenuListItemText
            data-cy="listItemFrequentlyAskedQuestions"
            primary="よくあるご質問"
          />
        </ListItemButton>
        <ListItemButton component={RouterLink} to={PATHS.INQUIRIES_NEW}>
          <StyledMenuListItemText
            data-cy="listItemInquires"
            primary="お問い合わせ"
          />
        </ListItemButton>
        <ListItemButton component={RouterLink} to={PATHS.TERMS_OF_SERVICE}>
          <StyledMenuListItemText
            data-cy="listItemTermsOfServices"
            primary="サービス利用規約"
          />
        </ListItemButton>
      </List>
    </StyledMenuDrawer>
  </>
);

export { Presenter };
