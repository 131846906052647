import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

export type Detail = {
  number: number;
  title: string;
  description: string;
  subDescription?: string;
  imgTitle?: string;
  img?: string;
};

type Props = {
  title: string;
  subTitle: string;
  details: Detail[];
  attention?: string;
};

const StyledSection = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));
const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledSectionSubTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    borderTop: '3px solid',
    borderColor: theme.palette.primary.light,
    content: '""',
    width: '12px',
    marginRight: theme.spacing(1),
  },
  '&::after': {
    borderTop: '3px solid',
    borderColor: theme.palette.primary.light,
    content: '""',
    width: '12px',
    marginLeft: theme.spacing(1),
  },
}));
const StyledDetailNumber = styled(Typography)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(6.5),
  marginTop: `-${theme.spacing(7.5)}`,
  fontSize: '2.375rem',
  color: theme.palette.background.highContrast,
  marginLeft: theme.spacing(6),
}));
const StyledDetailTextContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderTopLeftRadius: theme.spacing(0.25),
  borderTopRightRadius: theme.spacing(0.25),
  borderBottomLeftRadius: theme.spacing(3),
  borderBottomRightRadius: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));
const StyledDetailTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledDetailDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'inline-block',
  fontWeight: 'normal',
  color: theme.palette.secondary.dark,
}));
const StyledSubDetailDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'inline-block',
  fontWeight: 'normal',
  fontSize: '0.7rem',
  color: theme.palette.secondary.main,
}));
const StyledDetailImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledDetailImageTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.625),
  display: 'inline-block',
  color: theme.palette.grey.A400,
}));
const StyledDetailImage = styled('img')({
  width: '-webkit-fill-available',
});
const StyledAttention = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.secondary.dark,
  textAlign: 'left',
  margin: theme.spacing(0, 3),
}));

const Presenter: React.VFC<Props> = ({
  title,
  subTitle,
  details,
  attention,
}) => (
  <StyledSection container direction="row" justifyContent="center">
    <Grid size={{ xs: 12 }}>
      <StyledSectionTitle align="center" variant="h5">
        {title}
      </StyledSectionTitle>
      <StyledSectionSubTitle align="center" variant="body2">
        {subTitle}
      </StyledSectionSubTitle>
    </Grid>

    {details.map((detail) => (
      <Grid size={{ xs: 12 }} key={detail.number}>
        <StyledDetailNumber variant="body1">
          {detail.number.toString().padStart(2, '0')}
        </StyledDetailNumber>
        <StyledDetailTextContainer>
          <StyledDetailTitle variant="body1">{detail.title}</StyledDetailTitle>
          <StyledDetailDescription variant="caption">
            {detail.description}
          </StyledDetailDescription>
          {detail.subDescription && (
            <StyledSubDetailDescription variant="caption">
              {detail.subDescription}
            </StyledSubDetailDescription>
          )}
          {detail.img && (
            <StyledDetailImageContainer>
              {detail.imgTitle && (
                <StyledDetailImageTitle variant="caption">
                  {detail.imgTitle}
                </StyledDetailImageTitle>
              )}
              {detail.img && (
                <StyledDetailImage src={detail.img} alt={detail.title} />
              )}
            </StyledDetailImageContainer>
          )}
        </StyledDetailTextContainer>
      </Grid>
    ))}

    {attention && (
      <Grid size={{ xs: 12 }}>
        <StyledAttention align="center" variant="body2">
          {attention}
        </StyledAttention>
      </Grid>
    )}
  </StyledSection>
);

export { Presenter };
