import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '/@/store';
import { Presenter } from './Presenter';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const serialCode = urlSearchParams.get('serial_code');
  const serialCodeEndAt = urlSearchParams.get('end_at');

  const [isSerialCodeOutOfPeriod, setIsSerialCodeOutOfPeriod] =
    useState<boolean>(false);

  useEffect(() => {
    if (serialCodeEndAt) {
      // クエリパラメータ取得時に+がスペースに変換されてしまうため、元に戻す
      const endAt = new Date(serialCodeEndAt.replace(' ', '+')).getTime();
      const now = new Date().getTime();

      if (endAt < now) {
        setIsSerialCodeOutOfPeriod(true);
      }
    }
  }, [dispatch, serialCodeEndAt]);

  return (
    <Presenter
      url={null}
      origin={null}
      referer={null}
      convertToPoint={null}
      destination={null}
      isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
      serialCode={serialCode}
      serialCodeEndAt={serialCodeEndAt}
    />
  );
};

export { Container as LpChargeSerialCodePage };
